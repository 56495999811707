import React from "react";
import { useTranslation } from "react-i18next";

const Availability = () => {
  const { t, i18n } = useTranslation();

  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";

  return (
    <div className="select-availability-area pb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center---">
              {/* <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">{t('priceList')}</h6> */}
              <h1 className="section-title pt-20 text-center">
                {t("accomodationPrices")}
              </h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__select-availability-table-wrap">
              <div className="ltn__select-availability-table d-none d-md-block">
                <ul className="ltn__select-availability-table-head">
                  <li>{t("apartment")}</li>
                  <li>{t("capacity")}</li>
                  <li>{t("location")}</li>
                  <li>{t("view")}</li>
                  <li>01.07 - 01.09.</li>
                  <li>15.06-30.06 / 01.09.-15.09.</li>
                  <li>01.05.-15.06. / 15.09.-15.10.</li>
                  <li>15.10. – 01.05. {t("longerThan")}</li>
                  <li>{t("special")}</li>
                </ul>
                <ul className="ltn__select-availability-table-row">
                  <li className="tower-name">1</li>
                  <li>2+2(6)</li>
                  <li>{t("separated")}</li>
                  <li>{t("seaside")}</li>
                  <li>160 €</li>
                  <li>120 €</li>
                  <li>100 €</li>
                  <li>80 €</li>
                  <li>{t("airConditioning")}</li>
                </ul>
                <ul className="ltn__select-availability-table-row">
                  <li className="tower-name">4</li>
                  <li>2(5)</li>
                  <li>{t("firstFloor")}</li>
                  <li>{t("seaside")}</li>
                  <li>120 €</li>
                  <li>80 €</li>
                  <li>60 €</li>
                  <li>50 €</li>
                  <li>{t("airConditioning")}</li>
                </ul>
                <ul className="ltn__select-availability-table-row">
                  <li className="tower-name">6</li>
                  <li>2(2)</li>
                  <li>{t("firstFloor")}</li>
                  <li>{t("garden")}</li>
                  <li>60 €</li>
                  <li>40 €</li>
                  <li>30 €</li>
                  <li>30 €</li>
                  <li>{t("airConditioning")}</li>
                </ul>
                <ul className="ltn__select-availability-table-row">
                  <li className="tower-name">7</li>
                  <li>2(5)</li>
                  <li>{t("secondFloor")}</li>
                  <li>{t("seaside")}</li>
                  <li>120 €</li>
                  <li>80 €</li>
                  <li>60 €</li>
                  <li>50 €</li>
                  <li>{t("airConditioning")}</li>
                </ul>
                <ul className="ltn__select-availability-table-row">
                  <li className="tower-name">8</li>
                  <li>2(5)</li>
                  <li>{t("secondFloor")}</li>
                  <li>{t("seaside")}</li>
                  <li>120 €</li>
                  <li>80 €</li>
                  <li>60 €</li>
                  <li>50 €</li>
                  <li>{t("airConditioning")}</li>
                </ul>
                <ul className="ltn__select-availability-table-row">
                  <li className="tower-name">9</li>
                  <li>2(5)</li>
                  <li>{t("secondFloor")}</li>
                  <li>{t("garden")}</li>
                  <li>100 €</li>
                  <li>60 €</li>
                  <li>50 €</li>
                  <li>40 €</li>
                  <li>{t("airConditioning")}</li>
                </ul>
                <ul className="ltn__select-availability-table-row">
                  <li className="tower-name">10</li>
                  <li>2(3)</li>
                  <li>{t("secondFloor")}</li>
                  <li>{t("garden")}</li>
                  <li>80 €</li>
                  <li>50 €</li>
                  <li>40 €</li>
                  <li>30 €</li>
                  <li>{t("airConditioning")}</li>
                </ul>
                <ul className="ltn__select-availability-table-row">
                  <li className="tower-name">11</li>
                  <li>2(4)</li>
                  <li>{t("mansard")}</li>
                  <li>{t("seaTerrace")}</li>
                  <li>120 €</li>
                  <li>80 €</li>
                  <li>60 €</li>
                  <li>50 €</li>
                  <li>{t("airConditioning")}</li>
                </ul>
                <ul className="ltn__select-availability-table-row">
                  <li className="tower-name">12</li>
                  <li>2+2(6)</li>
                  <li>{t("mansard")}</li>
                  <li>{t("seaTerrace")}</li>
                  <li>150 €</li>
                  <li>110 €</li>
                  <li>90 €</li>
                  <li>70 €</li>
                  <li>{t("airConditioning")}</li>
                </ul>
                <ul className="ltn__select-availability-table-row">
                  <li className="tower-name">13</li>
                  <li>2(2)</li>
                  <li>{t("separated")}</li>
                  <li>{t("noTerrace")}</li>
                  <li>60 €</li>
                  <li>40 €</li>
                  <li>25 €</li>
                  <li>25 €</li>
                  <li>{t("airConditioning")}</li>
                </ul>
              </div>
              <div className="ltn__select-availability-table-responsive d-md-none">
                <ul className="ltn__select-availability-table-row-responsive-item">
                  <li>
                    <span>{t("apartment")}</span>{" "}
                    <span className="tower-name">1</span>
                  </li>
                  <li>
                    <span>{t("capacity")}</span> <span>2+2(6)</span>
                  </li>
                  <li>
                    <span>{t("location")}</span> <span>{t("separated")}</span>
                  </li>
                  <li>
                    <span>{t("view")}</span> <span>{t("seaside")}</span>
                  </li>
                  <li>
                    <span>01.07 - 01.09.</span> <span>160</span>
                  </li>
                  <li>
                    <span>15.06-30.06 / 01.09.-15.09.</span>{" "}
                    <span>
                      <a>120</a>
                    </span>
                  </li>
                  <li>
                    <span>01.05.-15.06. / 15.09.-15.10.</span>{" "}
                    <span>
                      <a>100</a>
                    </span>
                  </li>
                  <li>
                    <span>15.10. – 01.05. {t("longerThan")}</span>{" "}
                    <span>
                      <a>80</a>
                    </span>
                  </li>
                  <li>
                    <span>{t("special")}</span>{" "}
                    <span>
                      <a>{t("airConditioning")}</a>
                    </span>
                  </li>
                </ul>
                <ul className="ltn__select-availability-table-row-responsive-item">
                  <li>
                    <span>{t("apartment")}</span>{" "}
                    <span className="tower-name">4</span>
                  </li>
                  <li>
                    <span>{t("capacity")}</span> <span>2(5)</span>
                  </li>
                  <li>
                    <span>{t("location")}</span> <span>{t("firstFloor")}</span>
                  </li>
                  <li>
                    <span>{t("view")}</span> <span>{t("seaside")}</span>
                  </li>
                  <li>
                    <span>01.07 - 01.09.</span> <span>120</span>
                  </li>
                  <li>
                    <span>15.06-30.06 / 01.09.-15.09.</span>{" "}
                    <span>
                      <a>80</a>
                    </span>
                  </li>
                  <li>
                    <span>01.05.-15.06. / 15.09.-15.10.</span>{" "}
                    <span>
                      <a>60</a>
                    </span>
                  </li>
                  <li>
                    <span>15.10. – 01.05. {t("longerThan")}</span>{" "}
                    <span>
                      <a>50</a>
                    </span>
                  </li>
                  <li>
                    <span>{t("special")}</span>{" "}
                    <span>
                      <a>{t("airConditioning")}</a>
                    </span>
                  </li>
                </ul>
                <ul className="ltn__select-availability-table-row-responsive-item">
                  <li>
                    <span>{t("apartment")}</span>{" "}
                    <span className="tower-name">6</span>
                  </li>
                  <li>
                    <span>{t("capacity")}</span> <span>2(2)</span>
                  </li>
                  <li>
                    <span>{t("location")}</span> <span>{t("firstFloor")}</span>
                  </li>
                  <li>
                    <span>{t("view")}</span> <span>{t("garden")}</span>
                  </li>
                  <li>
                    <span>01.07 - 01.09.</span> <span>60</span>
                  </li>
                  <li>
                    <span>15.06-30.06 / 01.09.-15.09.</span>{" "}
                    <span>
                      <a>40</a>
                    </span>
                  </li>
                  <li>
                    <span>01.05.-15.06. / 15.09.-15.10.</span>{" "}
                    <span>
                      <a>30</a>
                    </span>
                  </li>
                  <li>
                    <span>15.10. – 01.05. {t("longerThan")}</span>{" "}
                    <span>
                      <a>30</a>
                    </span>
                  </li>
                  <li>
                    <span>{t("special")}</span>{" "}
                    <span>
                      <a>{t("airConditioning")}</a>
                    </span>
                  </li>
                </ul>
                <ul className="ltn__select-availability-table-row-responsive-item">
                  <li>
                    <span>{t("apartment")}</span>{" "}
                    <span className="tower-name">7</span>
                  </li>
                  <li>
                    <span>{t("capacity")}</span> <span>2(5)</span>
                  </li>
                  <li>
                    <span>{t("location")}</span> <span>{t("secondFloor")}</span>
                  </li>
                  <li>
                    <span>{t("view")}</span> <span>{t("seaside")}</span>
                  </li>
                  <li>
                    <span>01.07 - 01.09.</span> <span>120</span>
                  </li>
                  <li>
                    <span>15.06-30.06 / 01.09.-15.09.</span>{" "}
                    <span>
                      <a>80</a>
                    </span>
                  </li>
                  <li>
                    <span>01.05.-15.06. / 15.09.-15.10.</span>{" "}
                    <span>
                      <a>60</a>
                    </span>
                  </li>
                  <li>
                    <span>15.10. – 01.05. {t("longerThan")}</span>{" "}
                    <span>
                      <a>50</a>
                    </span>
                  </li>
                  <li>
                    <span>{t("special")}</span>{" "}
                    <span>
                      <a>{t("airConditioning")}</a>
                    </span>
                  </li>
                </ul>
                <ul className="ltn__select-availability-table-row-responsive-item">
                  <li>
                    <span>{t("apartment")}</span>{" "}
                    <span className="tower-name">8</span>
                  </li>
                  <li>
                    <span>{t("capacity")}</span> <span>2(5)</span>
                  </li>
                  <li>
                    <span>{t("location")}</span> <span>{t("secondFloor")}</span>
                  </li>
                  <li>
                    <span>{t("view")}</span> <span>{t("seaside")}</span>
                  </li>
                  <li>
                    <span>01.07 - 01.09.</span> <span>120</span>
                  </li>
                  <li>
                    <span>15.06-30.06 / 01.09.-15.09.</span>{" "}
                    <span>
                      <a>80</a>
                    </span>
                  </li>
                  <li>
                    <span>01.05.-15.06. / 15.09.-15.10.</span>{" "}
                    <span>
                      <a>60</a>
                    </span>
                  </li>
                  <li>
                    <span>15.10. – 01.05. {t("longerThan")}</span>{" "}
                    <span>
                      <a>50</a>
                    </span>
                  </li>
                  <li>
                    <span>{t("special")}</span>{" "}
                    <span>
                      <a>{t("airConditioning")}</a>
                    </span>
                  </li>
                </ul>
                <ul className="ltn__select-availability-table-row-responsive-item">
                  <li>
                    <span>{t("apartment")}</span>{" "}
                    <span className="tower-name">9</span>
                  </li>
                  <li>
                    <span>{t("capacity")}</span> <span>2(5)</span>
                  </li>
                  <li>
                    <span>{t("location")}</span> <span>{t("secondFloor")}</span>
                  </li>
                  <li>
                    <span>{t("view")}</span> <span>{t("garden")}</span>
                  </li>
                  <li>
                    <span>01.07 - 01.09.</span> <span>100</span>
                  </li>
                  <li>
                    <span>15.06-30.06 / 01.09.-15.09.</span>{" "}
                    <span>
                      <a>70</a>
                    </span>
                  </li>
                  <li>
                    <span>01.05.-15.06. / 15.09.-15.10.</span>{" "}
                    <span>
                      <a>50</a>
                    </span>
                  </li>
                  <li>
                    <span>15.10. – 01.05. {t("longerThan")}</span>{" "}
                    <span>
                      <a>40</a>
                    </span>
                  </li>
                  <li>
                    <span>{t("special")}</span>{" "}
                    <span>
                      <a>{t("airConditioning")}</a>
                    </span>
                  </li>
                </ul>
                <ul className="ltn__select-availability-table-row-responsive-item">
                  <li>
                    <span>{t("apartment")}</span>{" "}
                    <span className="tower-name">10</span>
                  </li>
                  <li>
                    <span>{t("capacity")}</span> <span>2(3)</span>
                  </li>
                  <li>
                    <span>{t("location")}</span> <span>{t("secondFloor")}</span>
                  </li>
                  <li>
                    <span>{t("view")}</span> <span>{t("garden")}</span>
                  </li>
                  <li>
                    <span>01.07 - 01.09.</span> <span>80</span>
                  </li>
                  <li>
                    <span>15.06-30.06 / 01.09.-15.09.</span>{" "}
                    <span>
                      <a>60</a>
                    </span>
                  </li>
                  <li>
                    <span>01.05.-15.06. / 15.09.-15.10.</span>{" "}
                    <span>
                      <a>40</a>
                    </span>
                  </li>
                  <li>
                    <span>15.10. – 01.05. {t("longerThan")}</span>{" "}
                    <span>
                      <a>30</a>
                    </span>
                  </li>
                  <li>
                    <span>{t("special")}</span>{" "}
                    <span>
                      <a>{t("airConditioning")}</a>
                    </span>
                  </li>
                </ul>
                <ul className="ltn__select-availability-table-row-responsive-item">
                  <li>
                    <span>{t("apartment")}</span>{" "}
                    <span className="tower-name">11</span>
                  </li>
                  <li>
                    <span>{t("capacity")}</span> <span>2(4)</span>
                  </li>
                  <li>
                    <span>{t("location")}</span> <span>{t("mansard")}</span>
                  </li>
                  <li>
                    <span>{t("view")}</span> <span>{t("seaTerrace")}</span>
                  </li>
                  <li>
                    <span>01.07 - 01.09.</span> <span>120</span>
                  </li>
                  <li>
                    <span>15.06-30.06 / 01.09.-15.09.</span>{" "}
                    <span>
                      <a>80</a>
                    </span>
                  </li>
                  <li>
                    <span>01.05.-15.06. / 15.09.-15.10.</span>{" "}
                    <span>
                      <a>60</a>
                    </span>
                  </li>
                  <li>
                    <span>15.10. – 01.05. {t("longerThan")}</span>{" "}
                    <span>
                      <a>50</a>
                    </span>
                  </li>
                  <li>
                    <span>{t("special")}</span>{" "}
                    <span>
                      <a>{t("airConditioning")}</a>
                    </span>
                  </li>
                </ul>
                <ul className="ltn__select-availability-table-row-responsive-item">
                  <li>
                    <span>{t("apartment")}</span>{" "}
                    <span className="tower-name">12</span>
                  </li>
                  <li>
                    <span>{t("capacity")}</span> <span>2+2(6)</span>
                  </li>
                  <li>
                    <span>{t("location")}</span> <span>{t("mansard")}</span>
                  </li>
                  <li>
                    <span>{t("view")}</span> <span>{t("seaTerrace")}</span>
                  </li>
                  <li>
                    <span>01.07 - 01.09.</span> <span>150</span>
                  </li>
                  <li>
                    <span>15.06-30.06 / 01.09.-15.09.</span>{" "}
                    <span>
                      <a>110</a>
                    </span>
                  </li>
                  <li>
                    <span>01.05.-15.06. / 15.09.-15.10.</span>{" "}
                    <span>
                      <a>90</a>
                    </span>
                  </li>
                  <li>
                    <span>15.10. – 01.05. {t("longerThan")}</span>{" "}
                    <span>
                      <a>70</a>
                    </span>
                  </li>
                  <li>
                    <span>{t("special")}</span>{" "}
                    <span>
                      <a>{t("airConditioning")}</a>
                    </span>
                  </li>
                </ul>
                <ul className="ltn__select-availability-table-row-responsive-item">
                  <li>
                    <span>{t("apartment")}</span>{" "}
                    <span className="tower-name">13</span>
                  </li>
                  <li>
                    <span>{t("capacity")}</span> <span>2(2)</span>
                  </li>
                  <li>
                    <span>{t("location")}</span> <span>{t("mansard")}</span>
                  </li>
                  <li>
                    <span>{t("view")}</span> <span>{t("noTerrace")}</span>
                  </li>
                  <li>
                    <span>01.07 - 01.09.</span> <span>60</span>
                  </li>
                  <li>
                    <span>15.06-30.06 / 01.09.-15.09.</span>{" "}
                    <span>
                      <a>40</a>
                    </span>
                  </li>
                  <li>
                    <span>01.05.-15.06. / 15.09.-15.10.</span>{" "}
                    <span>
                      <a>25</a>
                    </span>
                  </li>
                  <li>
                    <span>15.10. – 01.05. {t("longerThan")}</span>{" "}
                    <span>
                      <a>25</a>
                    </span>
                  </li>
                  <li>
                    <span>{t("special")}</span>{" "}
                    <span>
                      <a>{t("airConditioning")}</a>
                    </span>
                  </li>
                </ul>
              </div>
              <ul>
                <li>{t("lunch")}</li>
                <li>{t("residential")}</li>
                <li>{t("dog")}</li>
                <li>{t("cleaning")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Availability;
